import React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  contentsValueState,
  isOpenEmojiState,
  popContValueState
} from "../../recoil/contentsAtom";
import emoji from "../../utils/data/emoji.json";

function Emoji() {
  const [popContValue, setPopContValue] = useRecoilState(popContValueState);
  const setContentsValue = useSetRecoilState(contentsValueState);
  const [isOpenEmoji, setIsOpenEmoji] = useRecoilState(isOpenEmojiState);

  const selectEmoji = (i: number) => {
    setPopContValue({
      ...popContValue,
      content:
        popContValue.content +
        `<img src='${emoji.emoticon[i].image_url}' class='emoji'/>`
    });
    setContentsValue(
      popContValue.content +
        `<img src='${emoji.emoticon[i].image_url}' class='emoji'/>`
    );
    setIsOpenEmoji(false);
  };

  return isOpenEmoji ? (
    <ul className="emoji-list">
      {emoji.emoticon.map((i, idx) => (
        <li className="emojis" key={i.title} onClick={(e) => selectEmoji(idx)}>
          <img src={i.image_url} alt={i.title} />
        </li>
      ))}
    </ul>
  ) : null;
}

export default Emoji;
