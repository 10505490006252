import { useTranslation, withTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Link as ScrollMove } from "react-scroll";
import { Service_CN, Service_GL, redirectURL } from "../../../utils/CommonFunc";
import { useSetRecoilState } from "recoil";
import { MY_INFO_URL, SIGN_IN_URL } from "../../../utils/config/Config";
import { useRecoilValue } from "recoil";
import React, { useState } from "react";
import VWallet from "../vWallet/VWallet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  connectSiteState,
  isLoginStateSelector,
  userProfileKeyState
} from "../../../recoil/userInfoAtom";
import { sideMenuToggleState } from "../../../recoil/commonUiAtom";
import { useLoginCheck } from "hooks/useLoginCheck";
import { faAddressCard } from "@fortawesome/free-regular-svg-icons";
import { useMediaQuery } from "react-responsive";
import {
  faAnglesDown,
  faAnglesUp,
  faHome,
  faRightToBracket,
  faStar,
  faTableCellsLarge,
  faUser,
  faUserAstronaut,
  faWallet
} from "@fortawesome/free-solid-svg-icons";

function FixedFooter() {
  const { t } = useTranslation();
  const isLoginState = useRecoilValue(isLoginStateSelector);
  const { service_code } = useParams();
  const setOpenSideMenu = useSetRecoilState(sideMenuToggleState);
  const onClickSideMenu = () => {
    setOpenSideMenu((openSideMenu) => !openSideMenu);
  };
  const [isWalletOpen, setIsWalletOpen] = useState(false);
  const connectSite = useRecoilValue(connectSiteState);
  const isNotLogin = useLoginCheck();
  const userProfilekey = useRecoilValue(userProfileKeyState);

  const walletClick = () => {
    if (!isLoginState.isLogIn) {
      isNotLogin();
      return;
    }

    setIsWalletOpen((prev) => !prev);
  };

  const onClickMyinfo = () => {
    if (isLoginState.isLauncher) {
      window.app.vfunRequestLogin();
    } else {
      redirectURL(
        SIGN_IN_URL(service_code || "vfun"),
        "_self",
        isLoginState.isLauncher
      );
    }
  };

  const width320 = useMediaQuery({
    query: "screen and (max-width: 320px)"
  });

  return (
    <footer id="vfun-fixed-footer">
      <div className="row-w clearfix">
        <div className="wrap">
          <ul className="menu">
            {connectSite === "vfun" && (
              <>
                <li>
                  <Link
                    to="#"
                    onClick={() =>
                      redirectURL(
                        `${process.env.REACT_APP_PLATFORM_DNS}`,
                        "_self",
                        isLoginState.isLauncher
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faHome}
                      className="FontAwesomeIconSpan"
                    />
                    <br />
                    {!width320 && t("home")}
                  </Link>
                </li>
                <li>
                  <Link to="#" onClick={onClickSideMenu}>
                    <FontAwesomeIcon
                      icon={faTableCellsLarge}
                      className="FontAwesomeIconSpan"
                    />
                    <br />
                    {!width320 && t("menu")}
                  </Link>
                </li>

                {!isLoginState.isLogIn && (
                  <li>
                    <Link to="#" onClick={onClickMyinfo}>
                      <FontAwesomeIcon
                        icon={faRightToBracket}
                        className="FontAwesomeIconSpan"
                      />
                      <br />
                      {!width320 && t("signin")}
                    </Link>
                  </li>
                )}

                {Service_CN ? null : (
                  <li className="user-wallet">
                    <Link to="#" id="fWallet" onClick={walletClick}>
                      <FontAwesomeIcon
                        icon={faWallet}
                        className="FontAwesomeIconSpan"
                      />
                      <br />
                      {!width320 && t("v_wallet")}
                    </Link>
                    <VWallet isWalletOpen={isWalletOpen} className="fV" />
                  </li>
                )}
              </>
            )}

            {isLoginState.isLogIn && (
              <li className="my-space-btn">
                <Link to={`/space/${userProfilekey}`}>
                  <FontAwesomeIcon
                    icon={faUserAstronaut}
                    className="FontAwesomeIconSpan"
                  />
                  <br />
                  {!width320 && `My space`}
                </Link>
                <FontAwesomeIcon icon={faStar} className="star-tag" bounce />
              </li>
            )}
          </ul>

          {/* 페이지 스크롤 */}
          <ul className="go-to">
            <li>
              <ScrollMove smooth={true} to="valofe-top" title="위로">
                <FontAwesomeIcon
                  icon={faAnglesUp}
                  className="FontAwesomeIconSpan"
                />
              </ScrollMove>
            </li>
            <li>
              <ScrollMove
                smooth={true}
                to={connectSite === "vfun" ? "valofe-footer" : "valofeKoFooter"}
                title="아래로"
              >
                <FontAwesomeIcon
                  icon={faAnglesDown}
                  className="FontAwesomeIconSpan"
                />
              </ScrollMove>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
const FixedFooterMemo = React.memo(FixedFooter);
export default withTranslation()(FixedFooterMemo);
