import { atom } from "recoil";
import {
  createArrayAtom,
  createBooleanAtom,
  createNumberAtom,
  createStringAtom
} from "./commonAtom";

// PopPage 요소
export const popState = createBooleanAtom("popState", false);
export const cameraToggleState = createBooleanAtom("cameraToggleState", false);
export const isOpenEmojiState = createBooleanAtom("isOpenEmojiState", false);
export const imgBase64State = createArrayAtom<string>("imgBase64State", []);
export const imgFileState = atom<FileList | null>({
  key: "imgFileState",
  default: null,
  dangerouslyAllowMutability: true
});

//// contents 관련 요소
export const popContValueState = atom<{ title: string; content: string }>({
  key: "popContValueState",
  default: { title: "", content: "" }
});
export const contentsValueState = createStringAtom("contentsValueState", "");
export const writingTypeState = createStringAtom("writingTypeState", "");
export const parentsIdxState = createNumberAtom("parentsIdxState", 0);
export const isEditState = createBooleanAtom("isEditState", false);
export const editContentNumState = createNumberAtom("editContentNumState", 0);
export const editCommentNumState = createNumberAtom("editCommentNumState", 0);
export const ShareArticleToggle = atom<object>({
  key: "ShareArticleToggle",
  default: {}
});
export const snsListState = createStringAtom("snsListState", "");
export const isThroughMainState = createBooleanAtom(
  "isThroughMainState",
  false
);
export const isDetailState = createBooleanAtom("isDetailState", false);
export const isUserRightsState = createArrayAtom<any>("isUserRightsState", []);
export const isDeleteLoadingState = createBooleanAtom(
  "isDeleteLoadingState",
  false
);
export const viewTypeState = createStringAtom("viewTypeState", "");

export const isProfilePageState = createBooleanAtom(
  "isProfilePageState",
  false
);

export const isCreateProfilePopState = createBooleanAtom(
  "isCreateProfilePopState",
  false
);

export const editLoadingState = createBooleanAtom("editLoadingState", false);

export const successCommentState = createBooleanAtom(
  "successCommentState",
  false
);

export const spacePageTitleState = createStringAtom(
  "spacePageTitleState",
  "post"
);
