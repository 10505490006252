import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { IMAGE_DNS, MY_INFO_URL } from "../../../utils/config/Config";
import { useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import Language from "../language/Language";
import { useRecoilState, useRecoilValue } from "recoil";
import { redirectURL, Service_CN, Service_GL } from "../../../utils/CommonFunc";
import { useCookies, withCookies } from "react-cookie";
import SideMenu from "./sideMenu/SideMenu";
import LoginBtn from "./LoginBtn";
import VWallet from "../vWallet/VWallet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChevronLeft,
  faAngleDown,
  faWallet
} from "@fortawesome/free-solid-svg-icons";
import Grade from "./Grade";
import {
  isLoginStateSelector,
  userIdState,
  userNicknameState,
  userProfileKeyState
} from "../../../recoil/userInfoAtom";
import { sideMenuToggleState } from "../../../recoil/commonUiAtom";
import { useLoginCheck } from "hooks/useLoginCheck";
import { useMediaQuery } from "react-responsive";
import { IconProp, icon } from "@fortawesome/fontawesome-svg-core";

function CombineMenu(props: any) {
  const { t } = useTranslation();
  const isLoginState = useRecoilValue(isLoginStateSelector);
  const userId = useRecoilValue(userIdState);
  const userNickname = useRecoilValue(userNicknameState);
  const [openSideMenu, setOpenSideMenu] = useRecoilState(sideMenuToggleState);
  const [isWalletOpen, setIsWalletOpen] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies();
  const navigate = useNavigate();
  const userProfileKey = useRecoilValue(userProfileKeyState);
  const isNotLogin = useLoginCheck();

  const walletClick = () => {
    if (!isLoginState.isLogIn) {
      isNotLogin();
      return;
    }

    setIsWalletOpen((prev) => !prev);
  };

  const logout = () => {
    if (Service_CN) {
      redirectURL(
        `${process.env.REACT_APP_PLATFORM_DNS}/logout?redirect=http%3A%2F%2Fc9.bbs.xiyouxi.com%2F`,
        "_self",
        isLoginState.isLauncher
      );
    } else {
      Object.keys(cookies).forEach((keys) => {
        if (cookies["rememberId"] === "Y") {
          if (keys !== "userid" && keys !== "rememberId") {
            if (keys !== "locale") {
              removeCookie(keys, {
                path: "/",
                domain: process.env.REACT_APP_COOKIE_DOMAIN
              });
            }
          }
        } else {
          if (keys !== "locale") {
            removeCookie(keys, {
              path: "/",
              domain: process.env.REACT_APP_COOKIE_DOMAIN
            });
          }
        }

        if (isLoginState.isLauncher) {
          window.app.vfunRequestLogout();
          redirectURL(
            `${process.env.REACT_APP_PLATFORM_DNS}`,
            "_self",
            isLoginState.isLauncher
          );
        }
        redirectURL(window.location.href, "_self", isLoginState.isLauncher);
      });
    }
  };

  const onClickSideMenu = () => {
    setOpenSideMenu((openSideMenu) => !openSideMenu);
  };

  const goBack = (e: React.MouseEvent): void => {
    e.preventDefault();
    navigate(-1);
  };

  const logoImg = `${IMAGE_DNS}/Valofe_file/web/vfun/images/vfun_lounge/images/logo-lounge-blue-min.png`;

  const width768 = useMediaQuery({
    query: "screen and (max-width: 768px)"
  });

  return (
    <div id="combineMenu" className="clearfix">
      {/* 순수 헤더 */}
      <div className="wrap-cbm clearfix" id="page-wrap">
        {/* 햄버거 전 header-left */}
        <ul className="cb-menu pull-left">
          <li className="cbm-logo-li">
            <Link
              to="#"
              className={`cbm-logo ${Service_CN ? "cn" : ""}`}
              rel="noreferrer"
              onClick={() =>
                redirectURL(
                  process.env.REACT_APP_PLATFORM_DNS ||
                    "https://vfun.valofe.com",
                  "_self",
                  isLoginState.isLauncher
                )
              }
            />
          </li>
          <li className="cbm-back">
            <Link to="#" onClick={goBack}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </Link>
          </li>
          <li className="cbm-bar">
            <Link to="#" className="cbm-game">
              <FontAwesomeIcon icon={faBars} onClick={onClickSideMenu} />
            </Link>
          </li>

          <img src={logoImg} alt="logo" className="half-lounge-logo" />
        </ul>

        {/* SideMenu */}
        {openSideMenu ? <SideMenu logout={logout} /> : null}

        {/* header-right */}
        <fieldset>
          <legend className="hidden">LOGIN FORMULAR</legend>

          <img src={logoImg} alt="logo" className="full-lounge-logo" />

          {!isLoginState.isLogIn ? (
            // 로그인 전 header-right
            <LoginBtn type="combine" addclassname="login-area" />
          ) : (
            // 로그인 후 header-right
            <ul className="login-area pull-right">
              {Service_GL && !Service_CN ? (
                <li id="right-lang">
                  <Language />
                </li>
              ) : null}
              {/* 유저 정보 */}
              <li className="text user-id">
                <Link to="#" title={t("my_info")}>
                  {Service_GL && !Service_CN ? <Grade /> : null}
                  <span className="text-id" onClick={MY_INFO_URL}>
                    {userNickname === "" ? userId : userNickname}
                  </span>
                </Link>
              </li>
              {/* <li className="text latest-login">
                <Link to="#" title={t("latest_login")}>
                  <img
                    src={
                      IMAGE_DNS +
                      "/Valofe_file/web/vfun/images/common/icon/icon-clock.png"
                    }
                    alt=""
                  />
                  <span className="latest-time">{t("latest_login")}</span>
                </Link>
              </li> */}

              {/* 프로필 버튼 */}

              <li className="profile-btn">
                <Link to={`/space/${userProfileKey}`}>
                  <span className="my-space-btn">My Space</span>
                </Link>
              </li>

              {/* V Wallet */}
              {Service_CN ? (
                <li className="text">
                  <span
                    className="wallet-click"
                    onClick={() =>
                      redirectURL(
                        process.env.REACT_APP_BILLING_DNS ||
                          "https://vfun.valofe.com",
                        "_self",
                        isLoginState.isLauncher
                      )
                    }
                  >
                    <img
                      src={
                        IMAGE_DNS +
                        "/Valofe_file/web/vfun/images/common/icon/icon-wallet.png"
                      }
                      alt=""
                    />
                    {t("charge")}
                  </span>
                </li>
              ) : (
                <li className="text user-coin">
                  <span className="wallet-click" onClick={walletClick}>
                    <FontAwesomeIcon icon={faWallet} />
                    V Wallet
                    <FontAwesomeIcon icon={faAngleDown} />
                  </span>
                  <VWallet isWalletOpen={isWalletOpen} className="tV" />
                </li>
              )}

              <li className="login">
                <Link to="#" onClick={logout}>
                  {t("signout")}
                </Link>
              </li>
            </ul>
          )}
        </fieldset>
      </div>
    </div>
  );
}

export default withTranslation()(withCookies(CombineMenu));
