import { useRef, useState } from "react";
import { deletetag } from "../../utils/DeleteTag";
import parse from "html-react-parser";
import { useRecoilValue } from "recoil";
import { isDetailState } from "../../recoil/contentsAtom";

interface IProps {
  data: string;
  className: string;
  admin: number;
}

function ContentsTags(props: IProps) {
  const { data, className, admin } = props;
  const [isMore, setIsMore] = useState(false);
  const textLimit = useRef<number>(500);

  const dataLen: number | undefined = deletetag(data, [])?.length || 0;
  const isDetail = useRecoilValue(isDetailState);

  const contents = () => {
    const shortReview: string | undefined = deletetag(data, [
      "img",
      "br",
      "div"
    ])?.slice(0, textLimit.current);

    if (dataLen === undefined ? 0 : dataLen > textLimit.current) {
      if (isMore) {
        return deletetag(data, ["img", "br", "div"]);
      }
      return shortReview;
    }
    return deletetag(data, ["img", "br", "div"]);
  };

  return (
    <>
      <div className={className}>
        {parse(
          admin === 1
            ? data
            : deletetag(isDetail ? data : contents(), ["img", "br", "div"]) ||
                ""
        )}
      </div>

      {className === "text" &&
        (isDetail || admin === 1 ? null : (
          <button
            onClick={() => setIsMore((prev) => !prev)}
            className={dataLen > textLimit.current ? "more" : "not"}
          >
            {dataLen > textLimit.current && (isMore ? "hide" : "... more")}
          </button>
        ))}
    </>
  );
}

export default ContentsTags;
