import React, { useState, useEffect } from "react";
import "./PopPageWrite.scoped.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IMAGE_DNS } from "../../../utils/config/Config";
import {
  cameraToggleState,
  contentsValueState,
  editContentNumState,
  imgBase64State,
  imgFileState,
  isEditState,
  isProfilePageState,
  popContValueState,
  popState
} from "../../../recoil/contentsAtom";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import WrapLoading from "../../common/loading/WrapLoading";
import { useTranslation } from "react-i18next";
import {
  getBeforeContent,
  usePostContentsMutation,
  usePutContentsMutation
} from "../../../api/popPageWriteApi";
import Emoji from "../Emoji";
import PopPageBtns from "../popPageUI/PopPageBtns";
import PopPagePhotos from "../popPageUI/PopPagePhotos";
import PopPageContents from "../popPageUI/PopPageContents";
import { allowedTypes } from "../popPageCommonFunc/CommonFunc";
import {
  headersState,
  userInfoState,
  userNicknameState,
  userProfileImgState,
  userProfileKeyState
} from "../../../recoil/userInfoAtom";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BASE_PATH } from "../../../api/commonApi";
import {
  emptyContentCheckTKey,
  imgsSizeTkey,
  imgsTypeTkey
} from "utils/CommonTranslationKey";
import { useCookiesServiceCode } from "hooks/useCookiesServiceCode";

function PopPageWrite() {
  const { t } = useTranslation();
  const userInfo = useRecoilValue(userInfoState);
  const headers = useRecoilValue(headersState);
  const userNickname = useRecoilValue(userNicknameState);
  const { service_code, title, profilekey } = useParams();
  const [popContValue, setPopContValue] = useRecoilState(popContValueState);
  const setContentsValue = useSetRecoilState(contentsValueState);
  const [popOpen, setPopOpen] = useRecoilState(popState);
  const setImgBase64 = useSetRecoilState(imgBase64State);
  const [imgFile, setImgFile] = useRecoilState(imgFileState);
  const isEdit = useRecoilValue(isEditState);
  const editContentNum = useRecoilValue(editContentNumState);
  const setIsEdit = useSetRecoilState(isEditState);
  const [isLoading, setIsLoading] = useState(false);
  const [cameraToggle, setCameraToggle] = useRecoilState(cameraToggleState);
  const isProfilePage = useRecoilValue(isProfilePageState);
  const userProfileKey = useRecoilValue(userProfileKeyState);
  const userProfileImg = useRecoilValue(userProfileImgState);
  const navigate = useNavigate();
  const scCookies = useCookiesServiceCode();
  let postValue: any = {};

  // 공통
  const contentsReset = () => {
    setPopContValue({ title: "", content: "" });
    setContentsValue("");
    setIsEdit(false);
    setImgBase64([]);
    setImgFile(null);
    setPopOpen(false);
  };

  useEffect(() => {
    setCameraToggle(false);
  }, [popOpen]);

  const closeModal = () => {
    contentsReset();
    console.log("??");
  };

  // 게시물 쓰기
  const { mutate: postMutate } = usePostContentsMutation(
    isProfilePage ? "profile_board_list" : "page_board_list",
    "Post_writing_is_complete"
  );

  const postApi = isProfilePage
    ? `${BASE_PATH}/api/vfunlounge/profile/post`
    : `${BASE_PATH}/api/vfunlounge/posts/${service_code}/${title}`;

  const contentsSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (popContValue.content === "") {
      alert(t(emptyContentCheckTKey));
      return false;
    }
    if (popContValue.content !== null && popContValue.content !== "") {
      if (window.confirm(t("would_you_like_to_publish_this_post"))) {
      } else {
        return false;
      }
    }
    // image upload
    // append() 는 무조건 문자열로 자동 변환된다
    const formData = new FormData();
    if (imgFile !== null && imgFile !== undefined && imgFile.length !== 0) {
      for (let i = 0; i < imgFile.length; i++) {
        // 용량
        if (imgFile[i].size > 5242880) {
          alert(`${t(imgsSizeTkey)} ${imgFile[i].name}`);
          return false;
        }

        // 타입
        if (!allowedTypes.includes(imgFile[i].type)) {
          alert(
            t("the_file_formats_that_can_be_uploaded_are_gif_jpg_png_and_bmp")
          );
          return false;
        }
      }
      // 장 수
      if (imgFile.length >= 6) {
        alert(t("up_to_6_attachments_can_be_uploaded"));
        return false;
      }
      Object.values(imgFile).forEach((file: any) => {
        formData.append("images[]", file);
      });
    }
    formData.append("title", popContValue.title);
    formData.append("contents", popContValue.content);

    if (isProfilePage) {
      formData.append("user_profile_key", profilekey || "");
      formData.append("service_code", scCookies);
    }

    postValue = formData;
    const props = { postApi, postValue, headers };
    setIsLoading(true);

    postMutate(props, {
      onSuccess: (api) => {
        if (api.data.result === 1) {
          setIsLoading(false);
          contentsReset();
        } else if (api.data.result === -88) {
          setPopOpen(false);
          alert(t("profile_does_not_exist"));
          navigate(`/space/${userProfileKey}`);
        }
        setIsLoading(false);
      },
      onError: (error: any) => {
        alert(t("An error has occurred. please try again"));
        setIsLoading(false);
      }
    });
  };

  const getApi = isProfilePage
    ? `${BASE_PATH}/api/vfunlounge/profile/post/${editContentNum}?service_code=${scCookies}`
    : `${BASE_PATH}/api/vfunlounge/posts/${service_code}/${
        title === undefined ? "all" : title
      }/${editContentNum}`;

  // 게시물 수정하기
  //// 이전 내용 가져오기
  useEffect(() => {
    if (isEdit) {
      const props = { getApi, headers };
      getBeforeContent(props).then((res) => {
        if (res.result === 1) {
          const beforeImage = res.data.images;
          setPopContValue({ title: res.data.title, content: res.data.content });
          setContentsValue(res.data.content);
          setImgBase64(res.data.images);
          setCameraToggle(
            beforeImage === null || beforeImage.length === 0 ? false : true
          );
        }
      });
    }
  }, [isEdit, editContentNum]);

  //// 수정 내용 저장하기
  const { mutate: putMutate } = usePutContentsMutation(
    isProfilePage ? "profile_board_list" : "page_board_list",
    "The_post_has_been_edited"
  );

  const putApi = isProfilePage
    ? `${BASE_PATH}/api/vfunlounge/profile/post`
    : `${BASE_PATH}/api/vfunlounge/posts/${service_code}/${
        title === undefined ? "all" : title
      }/${editContentNum}`;

  const editSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (popContValue.content === "") {
      alert(t(emptyContentCheckTKey));
      return false;
    }

    if (popContValue.content !== null && popContValue.content !== "") {
      if (window.confirm(t("would_you_like_to_edit_your_post"))) {
      } else {
        return false;
      }
    }

    const formData = new FormData();
    if (imgFile !== null && imgFile !== undefined && imgFile.length !== 0) {
      for (let i = 0; i < imgFile.length; i++) {
        // 용량
        if (imgFile[i].size > 5242880) {
          alert(`${t(imgsSizeTkey)} ${imgFile[i].name}`);
          return false;
        }

        // 타입
        if (!allowedTypes.includes(imgFile[i].type)) {
          alert(t(imgsTypeTkey));
          return false;
        }
        // 장 수
        if (imgFile.length >= 6) {
          alert(t("up_to_6_attachments_can_be_uploaded"));
          return false;
        }
      }

      Object.values(imgFile).forEach((file: any) =>
        formData.append("images[]", file)
      );
    }

    if (isProfilePage) {
      formData.append("user_profile_key", profilekey || "");
      formData.append("board_idx", String(editContentNum));
      formData.append("service_code", scCookies);
    }

    formData.append("deleteimg", cameraToggle ? "0" : "1");
    formData.append("title", popContValue.title);
    formData.append("contents", popContValue.content);
    formData.append("_method", "PUT");

    postValue = formData;
    const props = { putApi, headers, postValue };
    setIsLoading(true);

    putMutate(props, {
      onSuccess: (api) => {
        if (api.data.result === 1) {
          contentsReset();
        }
        setIsLoading(false);
      },
      onError: (error: any) => {
        alert(t("An error has occurred. please try again"));
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    const escKeyModalClose = (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        setPopOpen(false);
      }
    };
    window.addEventListener("keydown", escKeyModalClose);
    return () => window.removeEventListener("keydown", escKeyModalClose);
  }, []);

  return isLoading ? (
    <WrapLoading />
  ) : (
    <div
      className="layer-wrap article-type"
      style={{ top: popOpen === true ? "1vh" : "100%" }}
    >
      <div className={popOpen === true ? "shadow" : ""} />
      <form
        className="pop-layer write"
        onSubmit={!isEdit ? contentsSubmit : editSubmit}
        encType="multipart/form-data"
      >
        {/* 팝업 상단 */}
        <h1 className="clearfix popUp-top">
          {!isEdit ? t("write_post") : t("write_edit")}
          <Link className="btn-layer-close" to="#" onClick={closeModal}>
            <FontAwesomeIcon icon={faCircleXmark} />
          </Link>
        </h1>

        <div className="pop-container animationOn">
          <h2 className="skip">VFUN 메세지</h2>
          <div className="frame">
            <div className="alert">
              <div className="info clearfix">
                <img
                  className="user-pic"
                  src={
                    userProfileImg.length !== 0
                      ? userProfileImg
                      : IMAGE_DNS +
                        "/Valofe_file/web/vfun-ko/images/common/img-user-default-min.png"
                  }
                  alt="프로필 - 디폴트"
                />
                <span className="nickname">
                  {userNickname === "" ? userInfo.Userid : userNickname}
                </span>
              </div>
              {/* 제목, 내용 */}
              <PopPageContents type="write" />
            </div>
          </div>
          {/* 사진 버튼과 이모지 버튼 */}
          <PopPageBtns writeType="write" />
          {/* 사진 */}
          <PopPagePhotos />
        </div>

        {/* 버튼 */}
        <div className="btn-wrap long">
          <button className="btn-post">
            {isEdit ? t("edit_post") : t("Post")}
          </button>
        </div>

        {/* 이모지 리스트 */}
        {popOpen && <Emoji />}
      </form>
    </div>
  );
}

export default PopPageWrite;
