import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import {
  BILLING_CHARGE_URL,
  VFUN_IMAGE_DNS
} from "../../../utils/config/Config";
import { redirectURL } from "../../../utils/CommonFunc";
import { useCookies } from "react-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { headersState, isLauncherState } from "../../../recoil/userInfoAtom";
import { getGrade } from "../../../api/userInfoApi";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function Grade() {
  const headers = useRecoilValue(headersState);
  const [cookies, setCookie, removeCookie] = useCookies();
  const service_code = cookies["service_code"];
  const isLauncher = useRecoilValue(isLauncherState);

  const { isLoading, data } = useQuery(["grade"], () => getGrade(headers), {
    refetchOnWindowFocus: false,
    notifyOnChangeProps: "tracked"
  });

  return !isLoading ? (
    data?.data.result === 1 ? (
      <img
        src={`${VFUN_IMAGE_DNS}/web/vfun/images/common/grade/${data.data.data}`}
        alt="g-new"
        onClick={() =>
          redirectURL(
            BILLING_CHARGE_URL(service_code || "vfun"),
            "_self",
            isLauncher
          )
        }
        style={{ marginLeft: "1rem" }}
      />
    ) : null
  ) : (
    <FontAwesomeIcon icon={faSpinner} pulse style={{ marginLeft: "1rem" }} />
  );
}

export default Grade;
