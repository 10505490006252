import "./Share.scoped.scss";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { PopupShare, Service_CN, onCopy } from "../../../utils/CommonFunc";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  isDetailState,
  isProfilePageState,
  ShareArticleToggle,
  snsListState
} from "../../../recoil/contentsAtom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isLauncherState } from "../../../recoil/userInfoAtom";
import { t } from "i18next";
import {
  faFacebookF,
  faXTwitter,
  faWeibo,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
import { faCircle, faCopy } from "@fortawesome/free-regular-svg-icons";

function ShareArticle(props: { idx: number }) {
  const snsList = useRecoilValue(snsListState);
  const sns = snsList.split("|").map((i) => i.toLowerCase());
  const setArticleToggle = useSetRecoilState(ShareArticleToggle);
  const { service_code, title, profilekey } = useParams();
  const isDetail = useRecoilValue(isDetailState);
  const isLauncher = useRecoilValue(isLauncherState);
  const isProfilePage = useRecoilValue(isProfilePageState);

  const matchUrl = () => {
    let url: string;

    if (isProfilePage) {
      if (!isDetail) {
        url = `${window.location.origin}/space/${profilekey}/${props.idx}`;
      } else {
        url = `${window.location.href}`;
      }
    } else {
      if (!isDetail && title === undefined) {
        url = `${window.location.origin}/page/${service_code}/all/${props.idx}`;
      } else if (!isDetail) {
        url = `${window.location.href}/${props.idx}`;
      } else {
        url = `${window.location.href}`;
      }
    }

    return url;
  };

  const snsType = (type: string) => {
    switch (type) {
      case "facebook":
        return faFacebookF;
      case "twitter":
        return faXTwitter;
      case "weibo":
        return faWeibo;
      case "instagram":
        return faInstagram;
      default:
        return faCircle;
    }
  };

  return (
    <span
      className="shareComponent feed"
      onMouseLeave={() => setArticleToggle({})}
    >
      {snsList?.length !== 0
        ? sns.map((i: string, idx: number) => (
            <span className="Share-item" key={idx}>
              <Link
                to="#"
                onClick={() => PopupShare(i, matchUrl(), sns[idx], isLauncher)}
              >
                <FontAwesomeIcon icon={snsType(i)} />
              </Link>
            </span>
          ))
        : null}
      {!Service_CN && (
        <span className="Share-item">
          <Link
            to="#"
            onClick={() =>
              onCopy(
                matchUrl(),
                t("Link_copied"),
                t("Link_copying_is_not_possible")
              )
            }
          >
            <FontAwesomeIcon icon={faCopy} />
          </Link>
        </span>
      )}
    </span>
  );
}

export default ShareArticle;
