import axios from "axios";
import { VFUN } from "../utils/config/request";
import { BASE_PATH } from "./commonApi";

export interface IMember {
  data: {
    email: string;
    nickName: string | null;
    user_Serial: string;
    user_birthday: string;
    user_id: string;
    profile_image_url: null | string;
    user_profile_key: string;
  };
  result: number;
  sso_info: string;
}

export const GET_MEMBERINFO = (headers: {}, service_code: string) => {
  return VFUN({
    url: `${BASE_PATH}/api/vfun/member_info?service_code=${service_code}`,
    method: "get",
    headers
  });
};

//// GCOIN
export interface IGCoin {
  result: number;
  data: {
    bonus_cash: number;
    real_cash: number;
    return_code: any;
    point: number;
  };
}

export const getGcoin = (
  service_code: string,
  headers: {
    Ssoinfo: string;
    Userid: string;
    Userbirth: string;
    channelingType: string;
  }
) =>
  axios
    .get(`${BASE_PATH}/api/vfun/gcoin/balance/${service_code}`, {
      headers: headers
    })
    .then((res) => res.data);

// 게임 좋아요
export const getGameLikeApi = (service_code: string) =>
  `${BASE_PATH}/api/vfunlounge/${service_code}/like`;

// 런처 로그인
export interface ILoginData {
  data: {
    birthday: string;
    check_email: string;
    email: string;
    member_type: string;
    service_code?: string;
    sso_info: string;
    sso_info_new: string;
    user_id: string;
  };
  result: number;
}

export const checkWebTokenApi = `${BASE_PATH}/api/auth/checkWebToken`;

export const getGrade = (headers: {
  Ssoinfo: string;
  Userid: string;
  Userbirth: string;
  channelingType: string;
}) =>
  axios.get(`${BASE_PATH}/api/vfun/billingGrade`, {
    headers: headers
  });

export const checkProfileNameApi = (
  profile_name: string,
  service_code: string
) =>
  `${BASE_PATH}/api/vfunlounge/profile/check_profile_name?user_nick_name=${profile_name}&service_code=${service_code}`;

export const uploadUserImgApi = (
  user_profile_key: string
) => `${BASE_PATH}/api/vfunlounge/profile/upload/${user_profile_key}
  `;

export interface IGetProfileInfo {
  data: {
    user_profile_info: {
      created_at: string;
      like_count: number;
      profile_background_image_url: string | null;
      profile_desc: string | null;
      profile_image_url: string | null;
      profile_key: number;
      user_id: string;
      updated_at: string | null;
    };
    user_profile_nickname: string;
    user_profile_like_status: boolean;
    owner: boolean;
    authorize: string;
    naver_home: string;
    follower_grade: number;
  };
  result: number;
}

export const getProfileInfoApi = (
  profile_key: string,
  service_code: string,
  headers: {
    Ssoinfo: string;
    Userid: string;
    Userbirth: string;
    channelingType: string;
  }
) =>
  axios
    .get(
      `${BASE_PATH}/api/vfunlounge/profile/${profile_key}?service_code=${service_code}`,
      {
        headers: headers
      }
    )
    .then((res) => res.data);
