import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { isEditState, writingTypeState } from "../../../recoil/contentsAtom";
import { popState } from "../../../recoil/contentsAtom";
import { checkPosition, naverAuthorizePopUp } from "../../../utils/CommonFunc";
import "./FloatWrite.scoped.scss";
import {
  isLoginStateSelector,
  isPrivacyAgreeState,
  naverAuthorizeUrl
} from "../../../recoil/userInfoAtom";
import { useLoginCheck } from "hooks/useLoginCheck";

function FloatWrite() {
  const { t } = useTranslation();
  const isLoginState = useRecoilValue(isLoginStateSelector);
  const setPopOpen = useSetRecoilState(popState);
  const setWritingType = useSetRecoilState(writingTypeState);
  const setIsEdit = useSetRecoilState(isEditState);
  const isPrivacyAgree = useRecoilValue(isPrivacyAgreeState);
  const naverAuthorize = useRecoilValue(naverAuthorizeUrl);
  const isNotLogin = useLoginCheck();

  const onClickOpen = () => {
    setIsEdit(false);
    setWritingType("content");

    // 로그인 x
    if (!isLoginState.isLogIn) {
      isNotLogin();
      return;
    }
    // 확인 필요

    // 네이버
    if (isPrivacyAgree) {
      setPopOpen(true);
    } else {
      checkPosition();
      naverAuthorizePopUp(naverAuthorize);
    }
  };

  return (
    <div className="float-wrapper">
      <button className="write-btn" onClick={onClickOpen} />
    </div>
  );
}

export default FloatWrite;
