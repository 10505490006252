import React, { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import "./PageCategory.scss";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  isProfilePageState,
  isUserRightsState,
  spacePageTitleState
} from "recoil/contentsAtom";
import { useQuery } from "react-query";
import { getLoungeSectionList, ILoungeSectionList } from "api/commonApi";
import CategoryLoading from "components/common/loading/CategoryLoading";

function PageCategory() {
  const { t } = useTranslation();
  const isProfilePage = useRecoilValue(isProfilePageState);
  const location = useLocation();
  const { service_code, title, profilekey } = useParams();
  const [spacePageTitle, setSpacePageTitle] =
    useRecoilState(spacePageTitleState);

  const { isLoading, data } = useQuery<ILoungeSectionList>(
    ["list", service_code],
    () => getLoungeSectionList(service_code || ""),
    {
      enabled: !(location.pathname.split("/")[1] === "profile")
    }
  );
  const setIsUserRights = useSetRecoilState(isUserRightsState);

  const allColor = title === undefined || title === "all" ? "on" : "";
  const postColor = spacePageTitle === "post" ? "on" : "";

  const onClickSpaceTitle = (title: string) => {
    if (isProfilePage) {
      setSpacePageTitle(title);
    }
  };

  useEffect(() => {
    if (data?.result === 1) {
      setIsUserRights(data.data);
    }
  }, [data]);

  return !isLoading ? (
    <nav className="category clearfix">
      <ul className="clearfix">
        <li
          className={!isProfilePage ? allColor : postColor}
          onClick={() => onClickSpaceTitle("post")}
        >
          <Link
            to={`${
              !isProfilePage
                ? `/page/${service_code}/all`
                : `/space/${profilekey}`
            }`}
          >
            <i className="icon board " />
            {t(!isProfilePage ? "all" : "posts")}
          </Link>
        </li>

        {data?.result === 1
          ? data?.data?.map((i) =>
              i.type === "L" ? (
                <li className={i.title === title ? "on" : ""} key={i.idx}>
                  <Link to={i.url} target="_blank">
                    {t(i.lang_code)}
                  </Link>
                </li>
              ) : (
                <li className={i.title === title ? "on" : ""} key={i.idx}>
                  <Link to={`/page/${service_code}/${i.title}`}>
                    {t(i.lang_code)}
                  </Link>
                </li>
              )
            )
          : null}

        {isProfilePage && (
          <>
            <li
              className={spacePageTitle === "like" ? "on" : ""}
              onClick={() => onClickSpaceTitle("like")}
            >
              <Link to={`/space/${profilekey}`}>{t("Like")}</Link>
            </li>
            <li
              className={spacePageTitle === "space" ? "on" : ""}
              onClick={() => onClickSpaceTitle("space")}
            >
              <Link to={`/space/${profilekey}`}>{t("space")}</Link>
            </li>
          </>
        )}
      </ul>
    </nav>
  ) : (
    <CategoryLoading />
  );
}
const PageCategoryMemo = React.memo(PageCategory);
export default withTranslation()(PageCategoryMemo);
