import React from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import { Service_GL, redirectURL } from "../../../utils/CommonFunc";
import { PRIVACY_URL, TERMS_URL } from "../../../utils/config/Config";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import {
  connectSiteState,
  isLauncherState
} from "../../../recoil/userInfoAtom";
import {
  ageGuidePopUpState,
  langCodeState
} from "../../../recoil/commonUiAtom";

const Entity_c = styled.span`
  font-family: "Arial", sans-serif;
`;
function Footer() {
  const { t } = useTranslation();
  const connectSite = useRecoilValue(connectSiteState);
  const langCode = useRecoilValue(langCodeState);
  const isLauncher = useRecoilValue(isLauncherState);
  const vfun = "https://www.valofe.com";
  const setAgePop = useSetRecoilState(ageGuidePopUpState);
  const { service_code } = useParams();

  const privacyPolicy = Service_GL
    ? `${process.env.REACT_APP_PLATFORM_DNS}/customer/g_pc_privacy?service_code=vfun&language=${langCode}`
    : PRIVACY_URL;
  const termsUrl = Service_GL
    ? `${process.env.REACT_APP_PLATFORM_DNS}/customer/g_pc_terms?service_code=vfun&language=${langCode}`
    : TERMS_URL;

  return connectSite === "vfun" ? (
    <footer id="valofe-footer" className="clearfix">
      <div className="row-w clearfix">
        <div className="line clearfix" />
      </div>
      <div className="row-w clearfix">
        <div className="copyright pull-left">
          <div className="footer-logo" id="">
            <span className="logo" />
          </div>
          <p>
            <Entity_c className="entity_c">©</Entity_c>
            <span className="year-of-copyrights">
              {new Date().getFullYear()}
            </span>
            {Service_GL
              ? " VALOFE GLOBAL Ltd. All rights reserved."
              : " VALOFE CO., LTD. All rights reserved."}
          </p>
        </div>
        <nav className="nav pull-right">
          <ul>
            <li>
              <Link
                to="#"
                onClick={() =>
                  redirectURL(`${vfun}/company/intro`, "_self", isLauncher)
                }
              >
                About VALOFE
              </Link>
            </li>

            <li>
              <Link
                to="#"
                onClick={() => redirectURL(privacyPolicy, "_self", isLauncher)}
              >
                {t("privacy_policy")}
              </Link>
            </li>
            <li>
              <Link
                to="#"
                onClick={() => redirectURL(termsUrl, "_self", isLauncher)}
              >
                {t("terms_of_agreement")}
              </Link>
            </li>

            {service_code === "soulworker" && !Service_GL ? (
              <li>
                <Link to={`/operation/${service_code}`}>운영 정책</Link>
              </li>
            ) : null}
          </ul>
        </nav>
      </div>
    </footer>
  ) : (
    <footer id="valofeKoFooter" className="clearfix">
      <div className="row-w cleafix">
        <span className="logo-footer">
          <a
            className="company-logo-footer"
            href="http://www.valofe.com"
            target="_blank"
            title="밸로프 홈페이지 바로가기"
          ></a>

          <a
            href="https://www.navercorp.com/"
            target="_blank"
            className="game-footer-logo naver"
          ></a>
        </span>
        <div className="ft-box">
          <nav id="gameFooterNavi">
            <ul className="clearfix">
              <li>
                <Link
                  to="#"
                  onClick={() =>
                    redirectURL(`${vfun}/company/intro`, "_blank", isLauncher)
                  }
                >
                  회사소개
                </Link>
                <span className="footer-space">|</span>
              </li>

              <li>
                <a href="https://luniaz.game.naver.com/support/terms_agree">
                  이용약관
                </a>
                <span className="footer-space">|</span>
              </li>
              <li style={{ cursor: "pointer" }}>
                <a onClick={() => setAgePop(true)}>게임이용등급안내</a>
                <span className="footer-space">|</span>
              </li>
              <li className="privacy">
                <a href="https://luniaz.game.naver.com/support/privacy_policy">
                  개인정보처리방침
                </a>
                <span className="footer-space">|</span>
              </li>
              <li>
                <a href="https://luniaz.game.naver.com/support/operation_policy">
                  운영정책
                </a>
              </li>
            </ul>
          </nav>
          <address>
            (주)밸로프
            <span className="footer-space">|</span>대표이사 신재명
            <span className="footer-space">|</span>서울특별시 금천구 디지털로
            130,601호 (가산동,남성프라자)
            <br />
            사업자등록번호 737-81-01610<span className="footer-space">|</span>
            통신판매업신고 2022-서울금천-3261
            <span className="footer-space">|</span>
            Tel 1599-4802<span className="footer-space">|</span>Fax 02-2028-1077{" "}
            <span className="footer-space">|</span>E-mail:&nbsp;
            <a href="mailto:valofe@valofe.com">valofe@valofe.com</a>
            <span className="footer-space">|</span>
            <a
              className="biz-info"
              href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=7378101610"
              target="_blank"
            >
              사업자정보확인
            </a>
          </address>
          <p className="copy">
            <span className="entity_c">©&nbsp;</span>
            <script>
              var CurrentYear = new Date().getFullYear();
              document.write(CurrentYear);{" "}
            </script>
            VALOFE CO., LTD. All rights reserved.
          </p>

          <span className="partner naver">
            네이버(주)는 통신판매중개자로서 게임의 거래에 대한 의무와 책임은 각
            제공자에게 있으며 이와 관련하여 발생하는 문제에 대해서 당사는
            법적책임을 부담하지 않습니다.
          </span>
        </div>
      </div>
    </footer>
  );
}

export default React.memo(withTranslation()(Footer));
