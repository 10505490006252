import { useState, useEffect } from "react";
import PopUp from "../../styles/PopUp";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { isCreateProfilePopState } from "../../../recoil/contentsAtom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  checkProfileNameApi,
  uploadUserImgApi
} from "../../../api/userInfoApi";
import {
  headersState,
  isProfileCreatedState,
  userNicknameState,
  userProfileImgState,
  userProfileKeyState
} from "../../../recoil/userInfoAtom";
import { BASE_PATH } from "../../../api/commonApi";
import WrapLoading from "../../common/loading/WrapLoading";
import { allowedTypes } from "../popPageCommonFunc/CommonFunc";
import "./CreateProfilePopUp.scoped.scss";
import { useCookiesServiceCode } from "hooks/useCookiesServiceCode";
import { t } from "i18next";

function OptionTitle(props: { option: string; isEssential: boolean }) {
  return (
    <span>
      {props.option}{" "}
      <p>({props.isEssential ? t("form_required") : t("optional")})</p>
    </span>
  );
}

function ValidTxt(props: { text: string }) {
  return (
    <div className="txt">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          d="M10 1H2C1.45 1 1.005 1.45 1.005 2L1 11L3 9H10C10.55 9 11 8.55 11 8V2C11 1.45 10.55 1 10 1ZM6.5 5.5H5.5V2.5H6.5V5.5ZM6.5 7.5H5.5V6.5H6.5V7.5Z"
          fill="#5BCAE6"
        />
      </svg>
      <div>{props.text}</div>
    </div>
  );
}

interface IProps {
  type: number;
  data?: {
    profile: string;
    profile_background: string;
    desc: string;
    nickname: string;
  };
}

function CreateProfilePopUp(props: IProps) {
  const userProfileKey = useRecoilValue(userProfileKeyState);
  const headers = useRecoilValue(headersState);
  const [inputValue, setInputValue] = useState<{
    nickName: string;
    desc: string;
  }>({
    nickName: "",
    desc: ""
  });
  const [isValid, setIsValid] = useState<{
    isSave: boolean;
    passNickname: boolean;
  }>({ isSave: false, passNickname: false });
  const [userNickName, setUserNickName] = useRecoilState(userNicknameState);
  const [isNicknameLoading, setIsNicknameLoading] = useState(false);
  const [isuploadLoading, setIsuploadLoading] = useState(false);
  const [imgBase64, setImgBase64] = useState<{
    profile: string;
    profile_background: string;
  }>({ profile: "", profile_background: "" });
  const [imgFile, setImgFile] = useState<{
    profile: FileList | null;
    profile_background: FileList | null;
  }>({ profile: null, profile_background: null });
  let url: {
    [key: string]: string;
  } = { profile: "", profile_background: "" };
  const navigate = useNavigate();
  const setIsCreateProfilePop = useSetRecoilState(isCreateProfilePopState);
  const setIsProfileCreated = useSetRecoilState(isProfileCreatedState);
  const setUserProfileImg = useSetRecoilState(userProfileImgState);
  const scCookies = useCookiesServiceCode();

  const onValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
  };

  useEffect(() => {
    setInputValue({ ...inputValue, nickName: userNickName });

    if (props.type === 1) {
      setInputValue({
        nickName: props.data?.nickname || "",
        desc: props.data?.desc || ""
      });
      setImgBase64({
        profile: props.data?.profile || "",
        profile_background: props.data?.profile_background || ""
      });
    }
  }, [props]);

  useEffect(() => {
    setIsValid({ ...isValid, isSave: inputValue.nickName.length !== 0 });
  }, [inputValue.nickName]);

  const handleAddImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    let uploadFile: FileList | null = e.target.files;
    const { name } = e.target;
    setImgFile({ ...imgFile, [name]: uploadFile });
    setImgBase64({ profile: "", profile_background: "" });

    let file = e.target.files!;
    if (file[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onloadend = () => {
        const base64 = reader.result;
        if (base64) {
          let base64Sub = base64.toString();
          setImgBase64({ ...imgBase64, [name]: base64Sub });
        }
      };
    }
  };

  const onClickNickNameCheckBtn = async () => {
    if (inputValue.nickName.length === 0) {
      alert(t("please_enter_a_nickname"));
      return;
    }

    if (inputValue.nickName.length > 12) {
      alert(t("profile_nickname_length_limit"));
      return;
    }

    setIsNicknameLoading(true);

    try {
      await axios
        .get(checkProfileNameApi(inputValue.nickName, scCookies), {
          headers: {
            ...headers
          }
        })
        .then((res) => {
          if (res.data.result === 1) {
            setIsValid({ ...isValid, passNickname: true });
          } else if (res.data.result === -96) {
            alert(t("already_registered_nickname"));
          } else {
            alert(t("my_inquiry_desc13"));
          }
        });
    } catch (error) {
      console.log(error, "profile nickname check");
    } finally {
      setIsNicknameLoading(false);
    }
  };

  const uploadImg = async (type: string) => {
    let typeImgFile =
      type === "profile" ? imgFile.profile : imgFile.profile_background;

    let img =
      type === "profile" ? imgBase64.profile : imgBase64.profile_background;

    if (img.split(":")[0] !== "http" || img.split(":")[0] !== "https") {
      if (typeImgFile !== null) {
        setIsuploadLoading(true);
        try {
          await axios
            .post(
              uploadUserImgApi(userProfileKey),
              {
                type: type,
                form_name: type,
                [type]: typeImgFile[0]
              },
              { headers: { "Content-Type": "multipart/form-data" } }
            )
            .then((res) => {
              const result = res.data.result;
              if (result === 1 || result === -91) {
                url[type] = res.data.data.url;
              } else if (result === -94) {
                alert(t("file_capacity_exceeded"));
              } else if (result === -93) {
                alert(t("disable_file_type"));
              } else {
                alert(t("my_inquiry_desc13"));
              }
            });
        } catch (error) {
          console.log(error, "img upload");
        } finally {
          setIsuploadLoading(false);
        }
      }
    }
  };

  const uploadInfo = async () => {
    if (inputValue.nickName.length === 0) {
      alert(t("please_enter_a_nickname"));
      return;
    }

    if (!isValid.passNickname && userNickName !== inputValue.nickName) {
      alert(t("please_need_duplicate_check_nickname"));
      return;
    }

    if (props.type === 2) {
      try {
        await axios
          .post(
            `${BASE_PATH}/api/vfunlounge/profile`,
            {
              user_profile_key: userProfileKey,
              user_nick_name: inputValue.nickName,
              user_profile_image_url: url.profile,
              user_profile_background_image_url: url.profile_background,
              user_profile_desc: inputValue.desc,
              service_code: scCookies
            },
            {
              headers: {
                ...headers
              }
            }
          )
          .then((res) => {
            if (res.data.result === 1) {
              alert(t("profile_create_complete"));
              setIsCreateProfilePop(false);
              setIsProfileCreated(true);
              setUserNickName(inputValue.nickName);
              setUserProfileImg(url.profile);
              navigate(`/space/${userProfileKey}`);
            } else {
              alert(t("my_inquiry_desc13"));
            }
          });
      } catch (error) {
        console.log(error, "profileApi");
      }
    } else if (props.type === 1) {
      try {
        await axios
          .put(
            `${BASE_PATH}/api/vfunlounge/profile`,
            {
              user_profile_key: userProfileKey,
              user_nick_name: inputValue.nickName,
              user_profile_image_url:
                url.profile !== "" ? url.profile : props.data?.profile,
              user_profile_background_image_url:
                url.profile_background !== ""
                  ? url.profile_background
                  : props.data?.profile_background,
              user_profile_desc: inputValue.desc,
              service_code: scCookies
            },
            {
              headers: {
                ...headers
              }
            }
          )
          .then((res) => {
            if (res.data.result === 1) {
              alert(t("profile_edit_complete"));
              setIsCreateProfilePop(false);
              setUserNickName(inputValue.nickName);
              setUserProfileImg(
                url.profile !== "" ? url.profile : props.data?.profile || ""
              );
            } else {
              alert(t("my_inquiry_desc13"));
            }
          });
      } catch (error) {
        console.log(error, "profileApi put");
      }
    }
  };

  const checkFileSize = (file: FileList | null) => {
    if (file !== null && file[0].size > 10485760) {
      alert(t("file_capacity_exceeded"));
      return true;
    }
    return false;
  };

  const checkFileType = (file: FileList | null) => {
    if (file !== null && !allowedTypes.includes(file[0].type)) {
      alert(t("disable_file_type"));
      return true;
    }
    return false;
  };

  const onClickSave = async () => {
    if (
      checkFileSize(imgFile.profile) ||
      checkFileSize(imgFile.profile_background)
    ) {
      return;
    }

    if (
      checkFileType(imgFile.profile) ||
      checkFileType(imgFile.profile_background)
    ) {
      return;
    }

    try {
      await uploadImg("profile");
      await uploadImg("profile_background");
    } catch (error) {
      console.log(error, "onClickSave");
    }

    uploadInfo();
  };

  const onClickCancle = () => {
    if (props.type === 2) {
      navigate(-1);
    }
    setIsCreateProfilePop(false);
  };

  // 중복 확인 후 수정 가능하게
  const onClickNicknameInput = () => {
    setIsValid({ ...isValid, passNickname: false });
  };

  useEffect(() => {
    if (inputValue.nickName === "") {
      setIsValid({ ...isValid, passNickname: false });
    }
  }, [inputValue.nickName]);

  return (
    <PopUp title={`${t("my_space")}`} id="createPop">
      <div className="box">
        <div className="row center">
          <OptionTitle option={t("nickname")} isEssential={true} />
          <input
            type="text"
            maxLength={12}
            value={inputValue.nickName || ""}
            autoFocus={true}
            name="nickName"
            placeholder={t("please_enter_a_nickname")}
            onChange={onValueChange}
            readOnly={isValid.passNickname}
            onClick={onClickNicknameInput}
          />
          <button
            onClick={onClickNickNameCheckBtn}
            className={`${isValid.passNickname ? "not" : ""} btn`}
          >
            {!isValid.passNickname ? (
              !isNicknameLoading ? (
                t("duplicate_check")
              ) : (
                <FontAwesomeIcon icon={faSpinner} pulse />
              )
            ) : (
              <FontAwesomeIcon icon={faCheck} />
            )}
          </button>
        </div>
      </div>

      <div className="box row">
        <OptionTitle option={t("profile_image")} isEssential={false} />
        <div className="box-cont">
          <div>
            <label htmlFor="profile-img">
              <span className="btn">{t("select_file")}</span>
              <span className="file-name ellipsis">
                {imgFile.profile === null
                  ? t("no_select_file")
                  : imgFile.profile[0].name}
              </span>
            </label>
            <input
              type="file"
              accept=".gif, .jpg, .png"
              multiple={false}
              name="profile"
              id="profile-img"
              onChange={handleAddImg}
              disabled={isuploadLoading}
            />
            <ValidTxt
              text={t(
                "images_can_only_be_JPG_GIF_and_PNG_files_within_2MB_and_the_preview_function_may_not_be_supported"
              )}
            />
            <p className="size">{t("profile_image_size")}</p>
          </div>
          <div className="img-box">
            {imgBase64.profile !== "" && <img src={imgBase64.profile} />}
          </div>
        </div>
      </div>

      <div className="box row">
        <OptionTitle option={t("profile_background")} isEssential={false} />
        <div className="box-cont">
          <div>
            <label htmlFor="profile-background-img">
              <span className="btn">{t("select_file")}</span>
              <span className="file-name ellipsis">
                {imgFile.profile_background === null
                  ? t("no_select_file")
                  : imgFile.profile_background[0].name}
              </span>
            </label>
            <input
              type="file"
              accept=".gif, .jpg, .png"
              multiple={false}
              name="profile_background"
              id="profile-background-img"
              onChange={handleAddImg}
              disabled={isuploadLoading}
            />
            <ValidTxt
              text={t(
                "images_can_only_be_JPG_GIF_and_PNG_files_within_2MB_and_the_preview_function_may_not_be_supported"
              )}
            />
            <p className="size">{t("profile_background_image_size")}</p>
          </div>
          <div className="img-box profile-background">
            {imgBase64.profile_background !== "" && (
              <img src={imgBase64.profile_background} />
            )}
          </div>
        </div>
      </div>
      <div className="box row">
        <OptionTitle option={t("profile_desc")} isEssential={false} />
        <div className="desc-area">
          <ValidTxt text={`${t("please_fill_out_within_200_characters")}`} />
          <textarea
            className="desc"
            maxLength={200}
            value={inputValue.desc}
            name="desc"
            onChange={onValueChange}
          />
        </div>
      </div>

      <div className="btns">
        <button onClick={onClickSave}>
          {props.type === 2 ? t("save") : t("edit")}
        </button>
        <button onClick={onClickCancle}>{t("cancle")}</button>
      </div>

      {isuploadLoading && <WrapLoading />}
    </PopUp>
  );
}

export default CreateProfilePopUp;
