import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useTranslation, withTranslation } from "react-i18next";
import { Service_GL, redirectURL } from "../../../utils/CommonFunc";
import { BILLING_CHARGE_URL } from "../../../utils/config/Config";
import { useCookies } from "react-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { headersState, isLauncherState } from "../../../recoil/userInfoAtom";
import { IGCoin, getGcoin } from "../../../api/userInfoApi";
import "./VWallet.scoped.scss";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  isWalletOpen: boolean;
  className: string;
}

function VWallet(props: IProps) {
  const { isWalletOpen, className } = props;
  const { t } = useTranslation();
  const headers = useRecoilValue(headersState);
  const [gcoin, setGcoin] = useState(0);
  const isLauncher = useRecoilValue(isLauncherState);
  const [cookies] = useCookies();
  const service_code = cookies["service_code"];

  const { isLoading, data } = useQuery<IGCoin>(
    ["gcoin"],
    () => getGcoin(service_code || "", headers),
    {
      enabled: !!isWalletOpen
    }
  );

  useEffect(() => {
    if (data?.result !== undefined && data.result === 1) {
      if (Service_GL) {
        setGcoin(data.data.bonus_cash + data.data.real_cash);
      } else {
        if (data.data.return_code[0] === "0000") {
          setGcoin(data.data.bonus_cash + data.data.real_cash);
        }
      }
    } else {
      setGcoin(0);
    }
  }, [data]);

  return (
    <ul
      id="vwallet"
      className={className}
      style={{
        display: isWalletOpen ? "block" : "none"
      }}
    >
      <li className="coin clearfix kr">
        <div className="clearfix">
          <span>V</span>
          <table>
            <tbody>
              <tr>
                <th>{Service_GL ? t("gcoins") : t("vcoins")}</th>
              </tr>
              <tr>
                <td>
                  {isLoading ? (
                    <FontAwesomeIcon icon={faSpinner} pulse />
                  ) : (
                    gcoin.toLocaleString()
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Link
          to="#"
          className="btn-charge"
          target="_self"
          title={t("charge")}
          onClick={() =>
            redirectURL(BILLING_CHARGE_URL(service_code), "_self", isLauncher)
          }
        >
          {t("charge")}
        </Link>
      </li>
      {Service_GL && (
        <li className="point clearfix">
          <div className="clearfix">
            <span>P</span>
            <table>
              <tbody>
                <tr>
                  <th>Points</th>
                </tr>
                <tr>
                  <td>
                    {!isLoading ? (
                      data?.result === 1 ? (
                        data.data.point
                      ) : (
                        0
                      )
                    ) : (
                      <FontAwesomeIcon icon={faSpinner} pulse />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Link
            to="#"
            className="btn-charge"
            title={"GET"}
            onClick={() =>
            redirectURL(`${process.env.REACT_APP_PLATFORM_DNS}/stamp?service_code=all&type=stamp_daily`, "_self", isLauncher)
          }
          >
            GET
          </Link>
        </li>
      )}
    </ul>
  );
}

export default React.memo(withTranslation()(VWallet));
