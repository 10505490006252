import "./PageHeader.scoped.scss";
import { Link, useParams } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Share from "../Share/Share";
import { snsListState } from "../../../recoil/contentsAtom";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { useQuery } from "react-query";
import { getLoungeInfo, ILoungeInfo } from "../../../api/commonApi";
import {
  Service_CN,
  checkPosition,
  naverAuthorizePopUp,
  redirectURL
} from "../../../utils/CommonFunc";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faThumbsUp,
  faSpinner,
  faGamepad,
  faShareNodes,
  faMagnifyingGlass,
  faXmark
} from "@fortawesome/free-solid-svg-icons";
import { useCookies } from "react-cookie";
import {
  headersState,
  isLoginStateSelector,
  isPrivacyAgreeState,
  naverAuthorizeUrl
} from "../../../recoil/userInfoAtom";
import {
  isOpenShareState,
  langCodeState,
  searchToggleState,
  youtubeIdState
} from "../../../recoil/commonUiAtom";
import { getGameLikeApi } from "../../../api/userInfoApi";
import { useLoginCheck } from "hooks/useLoginCheck";

function PageHeader() {
  const isLoginState = useRecoilValue(isLoginStateSelector);
  const headers = useRecoilValue(headersState);
  const langCode = useRecoilValue(langCodeState);
  const { service_code } = useParams();
  const { t } = useTranslation();
  const setIsOpenShare = useSetRecoilState(isOpenShareState);
  const [userBoardLike, setUserBoardLike] = useState(false);
  const isPrivacyAgree = useRecoilValue(isPrivacyAgreeState);
  const naverAuthorize = useRecoilValue(naverAuthorizeUrl);
  const [likeCount, setLikeCount] = useState(0);
  const [isLikeLoading, setIsLikeLoading] = useState(false);
  const setSnsList = useSetRecoilState(snsListState);
  const setYoutubeId = useSetRecoilState(youtubeIdState);
  const [searchToggle, setSearchToggle] = useRecoilState(searchToggleState);
  const [, setCookie] = useCookies();
  const isNotLogin = useLoginCheck();

  const { isLoading, data } = useQuery<ILoungeInfo>(
    ["gameInfo", langCode],
    () => getLoungeInfo(service_code || "", headers, langCode)
  );

  useEffect(() => {
    if (data?.result === -99) {
      alert(data.msg);
      return;
    }

    if (data?.result === 1) {
      setLikeCount(data?.data.like_count);
      setSnsList(data?.data.sns);
      setYoutubeId(data.data.youtube_id);
      setUserBoardLike(data?.user_like_status === 1 ? true : false);

      if (!Service_CN) {
        setCookie("service_code", data.data.service_code, {
          path: "/",
          domain: ".valofe.com"
        });
      }
    }
  }, [data]);

  const getLikeBoard = async () => {
    // 비 로그인 시
    if (!isLoginState.isLogIn) {
      isNotLogin();
      return;
    }

    // 네이버 채널링 관련 팝업
    if (!isPrivacyAgree) {
      checkPosition();
      return naverAuthorizePopUp(naverAuthorize);
    }

    if (!isLikeLoading) {
      try {
        setIsLikeLoading(true);
        await axios
          .post(
            getGameLikeApi(service_code || ""),
            {},
            { headers: { ...headers } }
          )
          .then((res) => {
            if (res.data.result === 1) {
              setLikeCount(res.data.data.like_count);
              setUserBoardLike(res.data.data.status);
            } else {
              alert(t("An_error_occured"));
            }
          });
      } catch (error) {
        alert(t("An_error_occured"));
      } finally {
        setIsLikeLoading(false);
      }
    }
  };

  const handleShareToggle = () => {
    setIsOpenShare((prev) => !prev);
  };

  return !isLoading ? (
    data?.result === 1 ? (
      <div id="page-header">
        <div className="row-w">
          <div className="left">
            <img
              className="thumb-img"
              src={data?.data.top_thumb_image_url}
              alt={`${data?.data.service_code} thumb-img`}
            />
            <span className="title">{data?.data.title}</span>
          </div>

          <div className="right">
            <span className="title">{data?.data.title}</span>
            {/* 홈페이지 버튼 */}
            <div>
              {data.data.web_url === null || data.data.web_url === "" ? null : (
                <button
                  className="btns home"
                  onClick={() =>
                    redirectURL(
                      data?.data.web_url,
                      "_self",
                      isLoginState.isLauncher
                    )
                  }
                  title={t("homepage")}
                >
                  <FontAwesomeIcon icon={faHome} />
                </button>
              )}

              {/* 사전예약/이벤트 버튼 */}
              {data?.data.pre_regist_url === null ||
              data.data.pre_regist_url === "" ? null : (
                <Link
                  to="#"
                  className="btns gamepad"
                  onClick={() =>
                    redirectURL(
                      data?.data.web_url,
                      "_self",
                      isLoginState.isLauncher
                    )
                  }
                  title={t("event")}
                >
                  <FontAwesomeIcon icon={faGamepad} />
                </Link>
              )}

              {/* 좋아요 */}
              <button
                className={`btns thumb ${userBoardLike ? "on" : ""}`}
                onClick={getLikeBoard}
                title={t("like")}
              >
                <FontAwesomeIcon icon={faThumbsUp} />
                {!isLikeLoading ? (
                  likeCount.toLocaleString()
                ) : (
                  <FontAwesomeIcon icon={faSpinner} pulse />
                )}
              </button>

              {/* 쉐어 버튼 */}
              <button
                className="btns share"
                onClick={handleShareToggle}
                title={t("share")}
              >
                <FontAwesomeIcon icon={faShareNodes} className="icon" />
                <Share snsList={data.data.sns} />
              </button>

              {/* 모바일 시 검색 버튼 */}
              <Link
                to="#"
                className={`btns mobile ${searchToggle ? "on" : ""}`}
                onClick={() => setSearchToggle((prev) => !prev)}
              >
                <FontAwesomeIcon
                  icon={searchToggle ? faXmark : faMagnifyingGlass}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    ) : null
  ) : null;
}

export default React.memo(withTranslation()(PageHeader));
